import axios from '../../utils/axios'

// DEFINITIONS
export const CONFIGS_BEGIN = 'CONFIGS_BEGIN'
export const CONFIGS_END = 'CONFIGS_END'

export const GET_CONFIG = 'GET_CONFIG'

// ACTION CREATORS
export const configBegin = () => ({ type: CONFIGS_BEGIN })
export const configEnd = () => ({ type: CONFIGS_END })

export const getConfigs = (configs, configType) => ({ type: GET_CONFIG, configs, configType })

// GET_CONFIG
export const startGetConfigs = (type) => {
  return async (dispatch, getState) => {
    dispatch(configBegin())
    try {
      const response = await axios.get(`${baseURL}/config/settings/${type}`)
      dispatch(getConfigs(response.data, type))
    } catch (error) {
      console.log(error.response)
      dispatch(configEnd())
    }
  }
}
