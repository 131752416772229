import axios from '../../utils/axios'
import { toast } from 'react-toastify'
import { history } from '../../routers/AppRouter'
import { startGetTenantProfile } from './tenantApplication'
import { startGetLandlordProfile } from "./landlordApplication"

// DEFINITIONS
export const DOCUMENTS_BEGIN = 'DOCUMENTS_BEGIN'
export const DOCUMENTS_END = 'DOCUMENTS_END'

export const GET_TEMPLATE_DOCUMENT = 'GET_TEMPLATE_DOCUMENT'
export const DOWNLOAD_TEMPLATE_DOCUMENT = 'DOWNLOAD_TEMPLATE_DOCUMENT'

export const UPLOAD_LANDLORD_DOCUMENT = 'UPLOAD_LANDLORD_DOCUMENT'
export const GET_LANDLORD_ADDITIONAL_DOCUMENTS = 'GET_LANDLORD_ADDITIONAL_DOCUMENTS'
export const DOWNLOAD_ADDITIONAL_LANDLORD_DOCUMENT = 'DOWNLOAD_ADDITIONAL_LANDLORD_DOCUMENT'

export const DELETE_LANDLORD_DOCUMENT = 'DELETE_LANDLORD_DOCUMENT'
export const DOWNLOAD_LANDLORD_DOCUMENT = 'DOWNLOAD_LANDLORD_DOCUMENT'

export const UPLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT = 'UPLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT'
export const DOWNLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT = 'DOWNLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT'
export const DELETE_RENTAL_PROPERTY_TENANT_DOCUMENT = 'DELETE_RENTAL_PROPERTY_TENANT_DOCUMENT'

export const UPLOAD_TENANT_DOCUMENT = 'UPLOAD_TENANT_DOCUMENT'
export const DELETE_TENANT_DOCUMENT = 'DELETE_TENANT_DOCUMENT'
export const DOWNLOAD_TENANT_DOCUMENT = 'DOWNLOAD_TENANT_DOCUMENT'

export const GET_ADDITIONAL_TENANT_DOCUMENT_TYPE = 'GET_ADDITIONAL_TENANT_DOCUMENT_TYPE'
export const UPLOAD_ADDITIONAL_TENANT_DOCUMENT = 'UPLOAD_ADDITIONAL_TENANT_DOCUMENT'
export const GET_TENANT_DOCUMENT_LIST = 'GET_TENANT_DOCUMENT_LIST'

export const DOWNLOAD_APPLICATION = 'DOWNLOAD_APPLICATION'

// ACTION CREATORS
export const documentsBegin = () => ({ type: DOCUMENTS_BEGIN })
export const documentsEnd = () => ({ type: DOCUMENTS_END })

export const getTemplateDocument = (templateDocument) => ({ type: GET_TEMPLATE_DOCUMENT, templateDocument })
export const downloadTemplateDocument = () => ({ type: GET_TEMPLATE_DOCUMENT })

export const uploadLandlordDocument = () => ({ type: UPLOAD_LANDLORD_DOCUMENT })
export const getLandlordAdditionalDocuments = (landlordAdditionalDocuments) => ({ type: GET_LANDLORD_ADDITIONAL_DOCUMENTS, landlordAdditionalDocuments })
export const deleteLandlordDocument = () => ({ type: DELETE_LANDLORD_DOCUMENT })
export const downloadLandlordDocument = () => ({ type: DOWNLOAD_LANDLORD_DOCUMENT })
export const downloadAdditionalLandlordDocument = () => ({ type: DOWNLOAD_ADDITIONAL_LANDLORD_DOCUMENT })

export const uploadRentalPropertyTenantDocument = () => ({ type: UPLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT })
export const downloadRentalPropertyTenantDocument = () => ({ type: DOWNLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT })
export const deleteRentalPropertyTenantDocument = () => ({ type: DELETE_RENTAL_PROPERTY_TENANT_DOCUMENT })

export const uploadTenantDocument = () => ({ type: UPLOAD_TENANT_DOCUMENT })
export const deleteTenantDocument = () => ({ type: DELETE_TENANT_DOCUMENT })
export const downloadTenantDocument = () => ({ type: DOWNLOAD_TENANT_DOCUMENT })

export const getAdditionalTenantDocumentType = (types) => ({ type: GET_ADDITIONAL_TENANT_DOCUMENT_TYPE, types })
export const uploadAdditionalTenantDocument = () => ({ type: UPLOAD_ADDITIONAL_TENANT_DOCUMENT })
export const getTenantDocumentList = (documentList) => ({ type: GET_TENANT_DOCUMENT_LIST, documentList })

export const downloadApplication = () => ({ type: DOWNLOAD_APPLICATION })

// GET_TEMPLATE_DOCUMENT
export const startGetTemplateDocument = (documentId) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios.get(`${baseURL}/documents/${documentId}`)
      // {
      //   "documentURL": "https://cd-generic-stage-public-docs.s3-us-west-1.amazonaws.com/W-9.pdf"
      // }

      const documents = getState().documents.templateDocuments
      const updated = {
        ...documents,
        [documentId]: response.data.documentURL
      }
      dispatch(getTemplateDocument(updated))
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// UPLOAD_LANDLORD_DOCUMENT
// export const startUploadLandlordDocument = (payload, type) => {
//   return async(dispatch, getState) => {
//     dispatch(documentsBegin())
//     try {
//       const landlordProfileID = getState().landlordApplication.profile.landlordProfileID
//       const config = { headers: { 'content-type': 'multipart/form-data' } }
//       const formData = new FormData()
//       formData.append('documentFile', payload)
//       const response = await axios.post(`${baseURL}/landlordProfiles/${landlordProfileID}/${type}`, formData, config)
//       dispatch(uploadLandlordDocument())
//       history.push('/application/landlord/documents')
//     } catch (error) {
//       console.log(error.response)
//       dispatch(documentsEnd())
//     }
//   }
// }

export const startGetLandlordAdditionalDocuments = () => {
  return async(dispatch) => {
    dispatch(documentsBegin())
    try {
      const response = await axios.get(`${baseURL}/landlord/additional`)
      dispatch(getLandlordAdditionalDocuments(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// UPLOAD_LANDLORD_DOCUMENT - New logic
export const startUploadLandlordDocument = (payload) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const landlordProfileID = getState().landlordApplication.profile.landlordProfileID
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      for (const type in payload) {
        const formData = new FormData()
        formData.append('documentFile', payload[type])
        const response = await axios.post(`${baseURL}/landlordProfiles/${landlordProfileID}/${type}`, formData, config)
      }
      dispatch(uploadLandlordDocument())
      dispatch(startGetLandlordProfile())
      toast.success('Documents Successfully Uploaded!')
      history.push('/application/landlord')
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

export const startDownloadLandlordAdditionalDocument = (documentId) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios({ url: `${baseURL}/landlordProfiles/additional/${documentId}`, method: 'GET', responseType: 'blob' })
      const a = document.createElement('a')
      const fileName = response.headers['content-disposition'].split('filename=')[1]
      a.download = decodeURI(fileName)
      a.href = window.URL.createObjectURL(new Blob([response.data]))
      document.body.append(a)
      a.click()
      dispatch(downloadTenantDocument())
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}
// UPLOAD_ADDITIONAL_TENANT_DOCUMENT
export const startUploadAdditionalLandlordDocument = (file, setSubmitting, toggle, type) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      const formData = new FormData()
      formData.append('documentFile', file)
      if (type) { formData.append('label', type) }
      const response = await axios.post(`${baseURL}/landlord/additional/`, formData, config)
      dispatch(uploadAdditionalTenantDocument())
      dispatch(startGetLandlordProfile())
      toast.success('Additional Document Successfully Uploaded!')
      dispatch(startGetLandlordAdditionalDocuments())
      if (toggle) { toggle() }
    } catch (error) {
      if (setSubmitting) { setSubmitting() }
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}
// DOWNLOAD_LANDLORD_DOCUMENT
export const startDownloadLandlordDocument = (documentId) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios({ url: `${baseURL}/landlordProfiles/documents/${documentId}`, method: 'GET', responseType: 'blob' })
      const a = document.createElement('a')
      const fileName = response.headers['content-disposition'].split('filename=')[1]
      a.download = decodeURI(fileName)
      a.href = window.URL.createObjectURL(new Blob([response.data]))
      document.body.append(a)
      a.click()
      dispatch(downloadLandlordDocument(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// DELETE_LANDLORD_DOCUMENT
export const startDeleteLandlordDocument = (documentId) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios.delete(`${baseURL}/landlordProfiles/documents/${documentId}`)
      dispatch(deleteLandlordDocument())
      history.push('/application/landlord/documents')
      toast.success('Documents Successfully Deleted!')
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// UPLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT
export const startUploadRentalPropertyTenantDocument = (payload) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const rentalPropertyTenantID = getState().landlordApplication.tenant.rentalPropertyTenantID
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      for (const type in payload) {
        const formData = new FormData()
        formData.append('documentFile', payload[type])
        const response = await axios.post(`${baseURL}/rentalPropertyTenants/${rentalPropertyTenantID}/${type}`, formData, config)
      }
      dispatch(uploadRentalPropertyTenantDocument())
      toast.success('Documents Successfully Uploaded!')
      history.push(`/application/landlord`)
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// DOWNLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT
export const startDownloadRentalPropertyTenantDocument = (documentID) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios({ url: `${baseURL}/rentalPropertyTenants/documents/${documentID}`, method: 'GET', responseType: 'blob' })
      const a = document.createElement('a')
      const fileName = response.headers['content-disposition'].split('filename=')[1]
      a.download = decodeURI(fileName)
      a.href = window.URL.createObjectURL(new Blob([response.data]))
      document.body.append(a)
      a.click()
      dispatch(downloadRentalPropertyTenantDocument(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// DELETE_LANDLORD_DOCUMENT
export const startDeleteRentalPropertyTenantDocument = (documentId, rentalPropertyTenantID) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios.delete(`${baseURL}/rentalPropertyTenants/documents/${documentId}`)
      dispatch(deleteRentalPropertyTenantDocument())
      history.push(`/application/landlord/tenant/${rentalPropertyTenantID}/documents`)
      toast.success('Documents Successfully Deleted!')
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// UPLOAD_TENANT_DOCUMENT
export const startUploadTenantDocument = (payload) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const tenantProfileID = getState().tenantApplication.profile.tenantProfileID
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      for (const type in payload) {
        const formData = new FormData()
        formData.append('documentFile', payload[type])
        const response = await axios.post(`${baseURL}/tenantProfile/${tenantProfileID}/${type}`, formData, config)
      }
      dispatch(uploadTenantDocument())
      dispatch(startGetTenantProfile())
      toast.success('Documents Successfully Uploaded!')
      history.push('/application/tenant')
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// DOWNLOAD_TENANT_DOCUMENT
export const startDownloadTenantDocument = (documentId) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios({ url: `${baseURL}/tenantProfile/documents/${documentId}`, method: 'GET', responseType: 'blob' })
      const a = document.createElement('a')
      const fileName = response.headers['content-disposition'].split('filename=')[1]
      a.download = decodeURI(fileName)
      a.href = window.URL.createObjectURL(new Blob([response.data]))
      document.body.append(a)
      a.click()
      dispatch(downloadTenantDocument())
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// DELETE_TENANT_DOCUMENT
export const startDeleteTenantDocument = (documentId) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios.delete(`${baseURL}/tenantProfile/documents/${documentId}`)
      dispatch(deleteTenantDocument())
      history.push('/application/tenant/documents')
      toast.success('Documents Successfully Deleted!')
      dispatch(startGetTenantProfile())
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// GET_ADDITIONAL_TENANT_DOCUMENT_TYPE
export const startGetAdditionalTenantDocumentType = () => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios.get(`${baseURL}/additional/document/types`)
      dispatch(getAdditionalTenantDocumentType(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// UPLOAD_ADDITIONAL_TENANT_DOCUMENT
export const startUploadAdditionalTenantDocument = (file, uploadOnly, setSubmitting, toggle, type) => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      const formData = new FormData()
      formData.append('documentFile', file)
      if (type) { formData.append('label', type) }
      const response = await axios.post(`${baseURL}/tenant/additional/`, formData, config)
      dispatch(uploadAdditionalTenantDocument())
      dispatch(startGetTenantProfile())
      if (!uploadOnly) { history.push('/application/tenant') }
      toast.success('Additional Document Successfully Uploaded!')
      dispatch(startGetDocumentList())
      if (toggle) { toggle() }
    } catch (error) {
      if (setSubmitting) { setSubmitting() }
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// GET_TENANT_DOCUMENT_LIST
export const startGetDocumentList = () => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios.get(`${baseURL}/tenant/documents`)
      dispatch(getTenantDocumentList(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}

// DOWNLOAD_APPLICATION
export const startDownloadApplication = () => {
  return async(dispatch, getState) => {
    dispatch(documentsBegin())
    try {
      const response = await axios({ url: `${baseURL}/download/application/`, method: 'GET', responseType: 'blob' })
      const a = document.createElement('a')
      const fileName = response.headers['content-disposition'].split('filename=')[1]
      a.download = decodeURI(fileName)
      a.href = window.URL.createObjectURL(new Blob([response.data]))
      document.body.append(a)
      a.click()
      dispatch(downloadApplication())
    } catch (error) {
      console.log(error.response)
      dispatch(documentsEnd())
    }
  }
}
