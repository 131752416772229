import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

//! should handle in BE??
const PassEligibleRoute = ({ component: Component, ...rest }) => {
  const isPassedPrescreening = useSelector(state => state.auth.isPassedPrescreening)
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
  const type = useSelector(state => state.auth.currentUser.type)

  return (
    <Route {...rest} component={(props) => (
      isLoggedIn
        ? <Redirect to={`/application/${type}`} />
        : isPassedPrescreening
          ? <Component {...props} />
          : <Redirect to='/' />
    )} />
  )
}

PassEligibleRoute.propTypes = {
  component: PropTypes.object
}

export default PassEligibleRoute
