import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const PublicRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
  const isLandlord = useSelector(state => state.auth.currentUser.isLandlord)

  const type = isLandlord ? 'landlord' : 'tenant'

  return (
    <Route {...rest} component={(props) => (
      isLoggedIn ? (
        <Redirect to={`/application/${type}`} /> //! Take them to their logged in home view??
      ) : (
        <Component {...props} />
      )
    )} />
  )
}

PublicRoute.propTypes = {
  component: PropTypes.object
}

export default PublicRoute
