import React, { Fragment, useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { history } from '../routers/AppRouter'
import { useLocation } from 'react-router-dom'

const NavBar = ({ isLoggedIn, onClickLogOut, onClickLogIn, onClickSignUp }) => {
  const dropdownMobile = useRef()
  const dropdownLanguage = useRef()
  const [activeDropdown, setActiveDropdown] = useState(false)
  const [activeLanguageDropdown, setLanguageDropdown] = useState(false)

  const location = useLocation()
  const { pathname } = location

  const handleDocumentClick = (e) => {
    if (dropdownMobile.current && dropdownMobile.current.contains(e.target)) return
    if (dropdownLanguage.current && dropdownLanguage.current.contains(e.target)) return
    setActiveDropdown(false)
    setLanguageDropdown(false)
  }

  const toggleDropdown = () => {
    setLanguageDropdown(!activeLanguageDropdown)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick)
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick)
    }
  }, [])

  return (
    <Fragment>
      <nav className='navbar col-12 p-0 fixed-top d-flex flex-row'>
        <div className='navbar-menu-wrapper d-flex align-items-center justify-content-between'>
          <div className='ml-3 pointer' onClick={() => history.push('/')}>
            <div className='mb-0'>
              <img src='../images/dca-full-logo.png' width={330} />
            </div>
          </div>
          <ul className='navbar-nav'>
            {/* FAQ */}
            <li className={`nav-item px-0 px-lg-3 d-none d-lg-flex justify-content-center ${pathname === '/faq' ? 'bg-light' : ''}`} >
              <div className='nav-link pointer d-flex align-items-center justify-content-center' onClick={() => { history.push('/faq') }}>
                <div>FAQ</div>
              </div>
            </li>

            {/* Required Documents */}
            <li className={`nav-item px-0 px-lg-3 d-none d-lg-flex justify-content-center ${pathname === '/required-documents' ? 'bg-light' : ''}`}>
              <div className='nav-link pointer d-flex align-items-center justify-content-center' onClick={() => { history.push('/required-documents') }}>
                <div>Required Documents</div>
              </div>
            </li>

            {/* Sample Forms */}
            <li className={`nav-item px-0 px-lg-3 d-none d-lg-flex justify-content-center ${pathname === '/sample-forms' ? 'bg-light' : ''}`}>
              <div className='nav-link pointer d-flex align-items-center justify-content-center' onClick={() => { history.push('/sample-forms') }}>
                <div>Sample Forms</div>
              </div>
            </li>

            {/* Report Fraud */}
            <li className={`nav-item px-0 px-lg-3 d-none d-lg-flex justify-content-center ${pathname === '/report-fraud' ? 'bg-light' : ''}`}>
              <div className='nav-link pointer d-flex align-items-center justify-content-center' onClick={() => { history.push('/report-fraud') }}>
                <div>Report Fraud</div>
              </div>
            </li>

            {/* Languages */}
            <li className={`nav-item px-3 dropdown ${activeLanguageDropdown ? 'show selected' : ''} d-none d-lg-flex justify-content-center`} ref={dropdownLanguage}>
              <div className='nav-link pointer d-flex align-items-center justify-content-center' onClick={toggleDropdown}>
                <div>
                  <span className='fas fa-globe mr-2' />Languages<span className='ml-3 fas fa-chevron-down' />
                </div>
              </div>

              <div className={`navbar-dropdown dropdown-menu shadow fh-regular pointer ${activeLanguageDropdown ? 'show' : ''}`}>

                <div className='dropdown-item' onClick={() => { setLanguageDropdown(false); history.push('/languages/spanish') }}>
                  <div className='body px-3'>Español</div>
                </div>

                <div className='dropdown-divider'></div>

                <div className='dropdown-item' onClick={() => { setLanguageDropdown(false); history.push('/languages/korean') }}>
                  <div className='body px-3'>한국어</div>
                </div>

                <div className='dropdown-divider'></div>

                <div className='dropdown-item' onClick={() => { setLanguageDropdown(false); history.push('/languages/vietnamese') }}>
                  <div className='body px-3'>Tiếng Việt</div>
                </div>

                <div className='dropdown-divider'></div>

                <div className='dropdown-item' onClick={() => { setLanguageDropdown(false); history.push('/languages/chinese') }}>
                  <div className='body px-3'>中文</div>
                </div>
              </div>
            </li>

            {!isLoggedIn
              ? (
                <li className='nav-link px-3 d-none d-lg-flex'>
                  <button className='btn btn-success btn-xs mx-1 mx-sm-3 text-white' type='button' onClick={onClickLogIn}>Log in</button>
                  {/* <button className='btn btn-success btn-xs' type='button' onClick={onClickSignUp}>Sign up</button> */}
                </li>
              ) : (
                <li className='nav-link px-3 d-none d-lg-flex'>
                  <button className='btn btn-warm-gray btn-xs mx-1 mx-sm-3 text-white' type='button' onClick={onClickLogOut}>Log out</button>
                </li>
              )}

            {/* TABLET/MOBILE */}
            <li className={`nav-item dropdown ${activeDropdown ? 'show selected' : ''} d-flex d-lg-none justify-content-center align-items-center`} ref={dropdownMobile}>
              <div className='nav-link mobile-menu pointer' onClick={() => setActiveDropdown(true)}>
                <span className='nav-icon fas fa-bars' />
              </div>

              <div className={`navbar-dropdown dropdown-menu shadow fh-regular pointer ${activeDropdown ? 'show' : ''}`}>

                <div className='dropdown-item' onClick={() => { setActiveDropdown(false); history.push('/faq') }}>
                  <div className='body px-3'><span>FAQ</span></div>
                </div>

                <div className='dropdown-divider'></div>

                <div className='dropdown-item' onClick={() => { setActiveDropdown(false); history.push('/required-documents') }}>
                  <div className='body px-3'><span>Required Documents</span></div>
                </div>

                <div className='dropdown-divider'></div>

                <div className='dropdown-item' onClick={() => { setActiveDropdown(false); history.push('/sample-forms') }}>
                  <div className='body px-3'><span>Sample Forms</span></div>
                </div>

                <div className='dropdown-divider'></div>

                <div className='dropdown-item' onClick={() => { setActiveDropdown(false); history.push('/report-fraud') }}>
                  <div className='body px-3'><span>Report Fraud</span></div>
                </div>

                <div className='dropdown-divider'></div>

                <div className='dropdown-item' onClick={() => { setActiveDropdown(false); history.push('/languages/select') }}>
                  <div className='body px-3'><span>Languages</span></div>
                </div>

                <div className='dropdown-divider'></div>

                {!isLoggedIn
                  ? (<div className='dropdown-item' onClick={() => { setActiveDropdown(false); onClickLogIn() }}>
                    <div className='body no-hover d-flex align-items-center px-3'>
                      <button className='btn btn-success btn-xs px-5 text-white' type='button'>Log in</button>
                    </div>
                  </div>
                  ) : (<div className='dropdown-item' onClick={() => { setActiveDropdown(false); onClickLogOut() }}>
                    <div className='body no-hover d-flex align-items-center px-3'>
                      <button className='btn btn-warm-gray btn-xs px-5 text-white' type='button'>Log out</button>
                    </div>
                  </div>
                  )}

              </div>
            </li>
          </ul>
        </div>
      </nav>
    </Fragment>
  )
}

NavBar.propTypes = {
  isLoggedIn: PropTypes.bool,
  onClickLogOut: PropTypes.func,
  onClickLogIn: PropTypes.func,
  onClickSignUp: PropTypes.func
}

export default NavBar
