import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import ls from 'local-storage'

import store from './redux/store'
import AppRouter from './routers/AppRouter'
import { login } from './redux/actions/auth'

import 'react-toastify/dist/ReactToastify.css'
import './styles/styles.scss'
import { ToastContainer } from 'react-toastify'

import { ScrollToTopButton } from './cdui'
import { animateScroll } from 'react-scroll'

if (ls('user')) {
  const user = ls('user')
  console.log('User: ', user)
  store.dispatch(login(user))
}

if (ls('authorization')) {
  ls.remove('authorization')
}

const jsx = (
  <Provider store={store}>
    <ToastContainer
      position='top-right'
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      draggable
      pauseOnHover />
    <AppRouter />
    <ScrollToTopButton handleClick={() => { animateScroll.scrollToTop() }} />
  </Provider>
)

ReactDOM.render(jsx, document.getElementById('app'))
