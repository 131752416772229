import {
  DOCUMENTS_BEGIN,
  DOCUMENTS_END,
  GET_TEMPLATE_DOCUMENT,
  DOWNLOAD_TEMPLATE_DOCUMENT,
  UPLOAD_LANDLORD_DOCUMENT,
  DELETE_LANDLORD_DOCUMENT,
  DOWNLOAD_LANDLORD_DOCUMENT,
  DELETE_RENTAL_PROPERTY_TENANT_DOCUMENT,
  UPLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT,
  DOWNLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT,
  UPLOAD_TENANT_DOCUMENT,
  DELETE_TENANT_DOCUMENT,
  DOWNLOAD_TENANT_DOCUMENT,
  GET_ADDITIONAL_TENANT_DOCUMENT_TYPE,
  UPLOAD_ADDITIONAL_TENANT_DOCUMENT,
  GET_TENANT_DOCUMENT_LIST,
  DOWNLOAD_APPLICATION, GET_LANDLORD_ADDITIONAL_DOCUMENTS
} from '../actions/documents'

const initialState = {
  templateDocuments: {},
  documentList: [],
  additionalDocumentTypes: [],
  landlordAdditionalDocuments: [],
  loading: false
}

const documentsReducer = (state = initialState, action) => {
  console.log('Reducer: ', action)
  const { type, templateDocument, documentList, types, landlordAdditionalDocuments } = action
  switch (type) {
    case DOCUMENTS_BEGIN:
      return { ...state, loading: true }

    case DOCUMENTS_END:
    case UPLOAD_LANDLORD_DOCUMENT:
    case DELETE_LANDLORD_DOCUMENT:
    case DOWNLOAD_LANDLORD_DOCUMENT:
    case DOWNLOAD_TEMPLATE_DOCUMENT:
    case UPLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT:
    case DOWNLOAD_RENTAL_PROPERTY_TENANT_DOCUMENT:
    case DELETE_RENTAL_PROPERTY_TENANT_DOCUMENT:
    case UPLOAD_TENANT_DOCUMENT:
    case DELETE_TENANT_DOCUMENT:
    case DOWNLOAD_TENANT_DOCUMENT:
    case UPLOAD_ADDITIONAL_TENANT_DOCUMENT:
    case DOWNLOAD_APPLICATION:
      return { ...state, loading: false }
    case GET_TEMPLATE_DOCUMENT:
      return { ...state, templateDocuments: templateDocument, loading: false }
    case GET_TENANT_DOCUMENT_LIST:
      return { ...state, documentList: documentList, loading: false }
    case GET_ADDITIONAL_TENANT_DOCUMENT_TYPE:
      return { ...state, additionalDocumentTypes: types }
    case GET_LANDLORD_ADDITIONAL_DOCUMENTS:
      return { ...state, landlordAdditionalDocuments }
    default:
      return state
  }
}

export default documentsReducer
