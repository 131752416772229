import {
  TENANT_APP_BEGIN,
  TENANT_APP_END,

  GET_TENANT_APPLICANT_INFO,
  UPDATE_TENANT_APPLICANT_INFO,
  SIGN_TENANT,
  GET_LL_SEARCH,
  INVITE_LANDLORD,

  GET_HOUSEHOLD_MEMBERS,
  GET_HOUSEHOLD_MEMBER,
  ADD_HOUSEHOLD_MEMBER,
  UPDATE_HOUSEHOLD_MEMBER,
  DELETE_HOUSEHOLD_MEMBER,

  GET_PROGRAMS,
  GET_PROGRAM,
  ADD_PROGRAM,
  UPDATE_PROGRAM,
  DELETE_PROGRAM,

  GET_UTILITIES,
  ADD_UTILITY,
  UPDATE_UTILITY,
  DELETE_UTILITY,

  GET_HOUSING_EXPENSES,
  ADD_HOUSING_EXPENSE,
  UPDATE_HOUSING_EXPENSE,
  DELETE_HOUSING_EXPENSE,

  SET_ESIGN_READY,
  GET_APP_STATUS,

  GET_MESSAGES,
  SEND_MESSAGE,
  UPDATE_MESSAGE,

  GET_COUNTIES
} from '../actions/tenantApplication'

const initialState = {
  profile: {},
  householdMembers: [],
  programs: [],
  utilities: [],
  housingExpenses: [],
  landlords: [],
  isEsignReady: false,
  loading: false,
  status: {
    status: '',
    message: '',
    payments: [],
    submitted: ''
  },
  messages: [],
  counties: []
}

const tenantApplicationReducer = (state = initialState, action) => {
  console.log('Reducer: ', action)
  const { type, tenant, householdMembers, programs, utilities, landlords, isEsignReady, status, messages, housingExpenses, counties } = action
  switch (type) {
    case TENANT_APP_BEGIN:
      return { ...state, loading: true }

    case TENANT_APP_END:
    case SIGN_TENANT:

    case GET_HOUSEHOLD_MEMBER:
    case ADD_HOUSEHOLD_MEMBER:
    case UPDATE_HOUSEHOLD_MEMBER:
    case DELETE_HOUSEHOLD_MEMBER:

    case GET_PROGRAM:
    case ADD_PROGRAM:
    case UPDATE_PROGRAM:
    case DELETE_PROGRAM:

    case ADD_UTILITY:
    case UPDATE_UTILITY:
    case DELETE_UTILITY:

    case ADD_HOUSING_EXPENSE:
    case UPDATE_HOUSING_EXPENSE:
    case DELETE_HOUSING_EXPENSE:

    case UPDATE_TENANT_APPLICANT_INFO:
    case INVITE_LANDLORD:

    case UPDATE_MESSAGE:
    case SEND_MESSAGE:
      return { ...state, loading: false }

    case GET_LL_SEARCH:
      return { ...state, loading: false, landlords }

    case GET_TENANT_APPLICANT_INFO:
      return { ...state, loading: false, profile: tenant }

    case GET_HOUSEHOLD_MEMBERS:
      return { ...state, loading: false, householdMembers }

    case GET_PROGRAMS:
      return { ...state, loading: false, programs }

    case GET_UTILITIES:
      return { ...state, loading: false, utilities }

    case GET_HOUSING_EXPENSES:
      return { ...state, loading: false, housingExpenses }

    case SET_ESIGN_READY:
      return { ...state, loading: false, isEsignReady }

    case GET_APP_STATUS:
      return { ...state, loading: false, status }

    case GET_MESSAGES:
      return { ...state, loading: false, messages }

    case GET_COUNTIES:
      return { ...state, loading: false, counties }

    default:
      return state
  }
}

export default tenantApplicationReducer
