import {
  LANDLORD_APP_BEGIN,
  LANDLORD_APP_END,
  GET_LANDLORD_PROFILE,
  GET_RENTAL_PROPERTY_TENANTS,
  SIGN_LANDLORD,
  GET_RENTAL_PROPERTY_TENANT,
  UPDATE_LANDLORD_PROFILE,
  UPDATE_RENTAL_PROPERTY_TENANT,
  DELETE_RENTAL_PROPERTY_TENANT,
  GET_RENTAL_PROPERTY_TENANT_STATUS,
  GET_MESSAGES,
  SEND_MESSAGE,
  UPDATE_MESSAGE
} from '../actions/landlordApplication'

const initialState = {
  profile: {},
  tenants: [],
  tenant: {},
  loading: false,
  status: [],
  messages: []
}

const landlordApplicationReducer = (state = initialState, action) => {
  console.log('Reducer: ', action)
  const { type, profile, tenants, tenant, status, messages } = action
  switch (type) {
    case LANDLORD_APP_BEGIN:
      return { ...state, loading: true }

    case LANDLORD_APP_END:
    case UPDATE_LANDLORD_PROFILE:
    case SIGN_LANDLORD:
    case UPDATE_RENTAL_PROPERTY_TENANT:
    case DELETE_RENTAL_PROPERTY_TENANT:
    case SEND_MESSAGE:
    case UPDATE_MESSAGE:
      return { ...state, loading: false }

    case GET_LANDLORD_PROFILE:
      return { ...state, loading: false, profile: profile }

    case GET_RENTAL_PROPERTY_TENANTS:
      return { ...state, loading: false, tenants: tenants }

    case GET_RENTAL_PROPERTY_TENANT:
      return { ...state, loading: false, tenant: tenant }

    case GET_RENTAL_PROPERTY_TENANT_STATUS:
      return { ...state, loading: false, status: status }

    case GET_MESSAGES:
      return { ...state, loading: false, messages }

    default:
      return state
  }
}

export default landlordApplicationReducer
