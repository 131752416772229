import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const PrivateTenantRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
  const isTenant = useSelector(state => state.auth.currentUser.isTenant)

  return (
    <Route {...rest} component={(props) => (
      isLoggedIn && isTenant ? (
        <Component {...props} />
      ) : (
        <Redirect to='/login' /> // Take them to their logged out home view
      )
    )} />
  )
}

PrivateTenantRoute.propTypes = {
  component: PropTypes.object
}

export default PrivateTenantRoute
