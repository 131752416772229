import axios from 'axios'
import ls from 'local-storage'
import { startLogout } from '../redux/actions/auth'
import store from '../redux/store'
import { toast } from 'react-toastify'

// Add a request interceptor
var axiosInstance = axios.create()
axiosInstance.defaults.withCredentials = true

// RESPONSE INTERCEPTOR
axiosInstance.interceptors.response.use((response) => {
  return response
}, (error) => {
  const { status } = error.response
  if (status === 440) {
    store.dispatch(startLogout())
    toast.warn('You\'ve been logged out due to inactivity.  Please log back in.')
  }

  if (status === 498) {
    // ! REFRESH TOKEN NEED TO BE IMPLEMENTED ON BE
  }
  return Promise.reject(error)
})

export default axiosInstance
