import axios from '../../utils/axios'
import { toast } from 'react-toastify'
import { history } from '../../routers/AppRouter'

// DEFINITIONS
export const LANDLORD_APP_BEGIN = 'LANDLORD_APP_BEGIN'
export const LANDLORD_APP_END = 'LANDLORD_APP_END'

export const GET_LANDLORD_PROFILE = 'GET_LANDLORD_PROFILE'
export const UPDATE_LANDLORD_PROFILE = 'UPDATE_LANDLORD_PROFILE'
export const SIGN_LANDLORD = 'SIGN_LANDLORD'

// export const DELETE_PROFILE = 'DELETE_PROFILE'

export const GET_RENTAL_PROPERTY_TENANTS = 'GET_RENTAL_PROPERTY_TENANTS'
export const GET_RENTAL_PROPERTY_TENANT = 'GET_RENTAL_PROPERTY_TENANT'
export const ADD_RENTAL_PROPERTY_TENANT = 'ADD_RENTAL_PROPERTY_TENANT'
export const UPDATE_RENTAL_PROPERTY_TENANT = 'UPDATE_RENTAL_PROPERTY_TENANT'
export const DELETE_RENTAL_PROPERTY_TENANT = 'DELETE_RENTAL_PROPERTY_TENANT'

export const GET_RENTAL_PROPERTY_TENANT_STATUS = 'GET_RENTAL_PROPERTY_TENANT_STATUS'

export const GET_MESSAGES = 'GET_MESSAGES'
export const SEND_MESSAGE = 'SEND_MESSAGE'
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE'

// ACTION CREATORS
export const landlordApplicationBegin = () => ({ type: LANDLORD_APP_BEGIN })
export const landlordApplicationEnd = () => ({ type: LANDLORD_APP_END })

/// LANDLORD PROFILE
export const getLandlordProfile = (profile) => ({ type: GET_LANDLORD_PROFILE, profile })
export const updateLandlordProfile = () => ({ type: UPDATE_LANDLORD_PROFILE })
export const signLandlord = () => ({ type: SIGN_LANDLORD })

/// LANDLORD PROPERTIES TENANTS
export const getRentalPropertyTenants = (tenants) => ({ type: GET_RENTAL_PROPERTY_TENANTS, tenants })
export const getRentalPropertyTenant = (tenant) => ({ type: GET_RENTAL_PROPERTY_TENANT, tenant })
export const addRentalPropertyTenant = () => ({ type: ADD_RENTAL_PROPERTY_TENANT })
export const updateRentalPropertyTenant = () => ({ type: UPDATE_RENTAL_PROPERTY_TENANT })
export const deleteRentalPropertyTenant = () => ({ type: DELETE_RENTAL_PROPERTY_TENANT })

// GET RENTAL PROPERTY TENANT STATUS
export const getStatus = (status) => ({ type: GET_RENTAL_PROPERTY_TENANT_STATUS, status })

// MESSAGE
export const getMessages = (messages) => ({ type: GET_MESSAGES, messages })
export const sendMessage = () => ({ type: SEND_MESSAGE })
export const updateMessage = () => ({ type: UPDATE_MESSAGE })

// ////////////////////////////////////
// /////// LANDLORD PROFILE //////////
// //////////////////////////////////

// GET_LANDLORD_PROFILE
export const startGetLandlordProfile = () => {
  return async (dispatch, getState) => {
    dispatch(landlordApplicationBegin())
    try {
      const response = await axios.get(`${baseURL}/user/landlordProfile`)
      dispatch(getLandlordProfile(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(landlordApplicationEnd())
    }
  }
}

// UPDATE_LANDLORD_PROFILE
export const startUpdateLandlordProfile = (payload) => {
  return async (dispatch, getState) => {
    dispatch(landlordApplicationBegin())
    try {
      const response = await axios.put(`${baseURL}/user/landlordProfile`, payload)
      dispatch(updateLandlordProfile())
      toast.success('Profile successfully Updated!')
      history.push('/application/landlord')
    } catch (error) {
      console.log(error.response)
      dispatch(landlordApplicationEnd())
    }
  }
}

// SIGN_LANDLORD
export const startAddLandLordSignature = (payload, setSubmitting, rentalPropertyTenantID) => {
  return async (dispatch, getState) => {
    dispatch(landlordApplicationBegin())
    try {
      const response = await axios.post(`${baseURL}/signature/landlord/?rentalPropertyTenantID=${rentalPropertyTenantID}`, payload)
      dispatch(signLandlord())
      toast.success('Signed!')
      history.push('/application/landlord')
    } catch (error) {
      setSubmitting()
      console.log(error.response)
      dispatch(landlordApplicationEnd())
    }
  }
}


// ////////////////////////////////////////////
// /////// LANDLORD PROPERTY TENANTS /////////
// //////////////////////////////////////////

// GET_RENTAL_PROPERTY_TENANTS
export const startGetRentalPropertyTenants = () => {
  return async (dispatch, getState) => {
    dispatch(landlordApplicationBegin())
    try {
      const response = await axios.get(`${baseURL}/rentalPropertyTenants`)
      dispatch(getRentalPropertyTenants(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(landlordApplicationEnd())
    }
  }
}

// GET_RENTAL_PROPERTY_TENANT
export const startGetRentalPropertyTenant = (rentalPropertyTenantID) => {
  return async (dispatch, getState) => {
    dispatch(landlordApplicationBegin())
    try {
      const response = await axios.get(`${baseURL}/rentalPropertyTenants/${rentalPropertyTenantID}`)
      dispatch(getRentalPropertyTenant(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(landlordApplicationEnd())
    }
  }
}

// ADD_RENTAL_PROPERTY_TENANT
export const startAddRentalPropertyTenant = (payload, setSubmitting) => {
  return async (dispatch, getState) => {
    dispatch(landlordApplicationBegin())
    try {
      const response = await axios.post(`${baseURL}/rentalPropertyTenants`, payload)
      toast.success('Tenant Created')
      history.push(`/application/landlord`)
    } catch (error) {
      if (error.response.data.message === 'OUT_OF_BOUNDS') {
        toast.warning('The tenant\'s zip code is outside of the program\'s reach')
      }
      setSubmitting()
      dispatch(landlordApplicationEnd())
    }
  }
}

// UPDATE_RENTAL_PROPERTY_TENANT
export const startUpdateRentalPropertyTenant = (payload, setSubmitting, tenantID) => {
  return async (dispatch, getState) => {
    dispatch(landlordApplicationBegin())
    try {
      const response = await axios.put(`${baseURL}/rentalPropertyTenants/${tenantID}`, payload)
      toast.success('Tenant Updated')
      history.push(`/application/landlord`)
    } catch (error) {
      if (error.response.data.message === 'OUT_OF_BOUNDS') {
        toast.warning('The tenant\'s zip code is outside of the program\'s reach')
      }
      console.log(error.response)
      setSubmitting()
      dispatch(landlordApplicationEnd())
    }
  }
}

// DELETE_RENTAL_PROPERTY_TENANT
export const startDeleteRentalPropertyTenant = (rentalPropertyTenantID, toggle) => {
  return async (dispatch, getState) => {
    dispatch(landlordApplicationBegin())
    try {
      const response = await axios.delete(`${baseURL}/rentalPropertyTenants/${rentalPropertyTenantID}`)
      dispatch(deleteRentalPropertyTenant(response.data))
      toggle()
      history.push('/application/landlord')
    } catch (error) {
      console.log(error.response)
      dispatch(landlordApplicationEnd())
    }
  }
}

// /////////////////////////////////////
// /////// APPLICATION STATUS //////////
// /////////////////////////////////////

// GET_RENTAL_PROPERTY_TENANT_STATUS

export const startGetStatus = () => {
  return async(dispatch, getState) => {
    dispatch(landlordApplicationBegin())
    try {
      const response = await axios.get(`${baseURL}/landlord/status`)
      dispatch(getStatus(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(landlordApplicationEnd())
    }
  }
}

// ////////////////////////////
// /////// MESSAGES //////////
// ///////////////////////////

// GET_MESSAGES
export const startGetMessages = () => {
  return async(dispatch, getState) => {
    dispatch(landlordApplicationBegin())
    try {
      const response = await axios.get(`${baseURL}/messages`)
      dispatch(getMessages(response.data.conversation))
    } catch (error) {
      console.log(error.response)
      dispatch(landlordApplicationEnd())
    }
  }
}

// SEND_MESSAGE
export const startSendMessage = (payload, setSubmitting, resetForm) => {
  return async(dispatch, getState) => {
    dispatch(landlordApplicationBegin())
    try {
      const response = await axios.post(`${baseURL}/messages`, payload)
      dispatch(sendMessage())
      dispatch(startGetMessages())
      toast.success('Sent!')
      setSubmitting()
      resetForm({
        values: {
          text: ''
        }
      })
      dispatch(startUpdateMessage())
    } catch (error) {
      console.log(error.response)
      setSubmitting()
      dispatch(landlordApplicationEnd())
    }
  }
}

// UPDATE_MESSAGE
export const startUpdateMessage = () => {
  return async(dispatch, getState) => {
    dispatch(landlordApplicationBegin())
    try {
      await dispatch(startGetMessages())
      const messages = getState().tenantApplication.messages
      const messageIDs = messages.map(d => d.messageID)
      const payload = {
        messageIDs: messageIDs
      }
      const response = await axios.put(`${baseURL}/messages/read`, payload)
      dispatch(updateMessage())
      dispatch(startGetMessages())
    } catch (error) {
      console.log(error.response)
      dispatch(landlordApplicationEnd())
    }
  }
}
