import {
  CONFIGS_BEGIN,
  CONFIGS_END,
  GET_CONFIG
} from '../actions/config'

const initialState = {
  configs: {
    bannerText: {}
  },
  loading: false
}

const assetReducer = (state = initialState, action) => {
  console.log('Reducer: ', action)
  const { type, configs, configType } = action
  switch (type) {
    case CONFIGS_BEGIN:
      return { ...state, loading: true }

    case CONFIGS_END:
      return { ...state, loading: false }

    case GET_CONFIG:
      return { ...state, configs: { ...state.configs, [configType]: configs }, loading: false }

    default:
      return state
  }
}

export default assetReducer
