import axios from '../../utils/axios'
import { toast } from 'react-toastify'
import { history } from '../../routers/AppRouter'
import ls from 'local-storage'

/// DEFINITIONS
export const TENANT_APP_BEGIN = 'TENANT_APP_BEGIN'
export const TENANT_APP_END = 'TENANT_APP_END'

export const GET_TENANT_APPLICANT_INFO = 'GET_TENANT_APPLICANT_INFO'
export const UPDATE_TENANT_APPLICANT_INFO = 'UPDATE_TENANT_APPLICANT_INFO'
export const SIGN_TENANT = 'SIGN_TENANT'
export const GET_LL_SEARCH = 'GET_LL_SEARCH'
export const INVITE_LANDLORD = 'INVITE_LANDLORD'

export const GET_HOUSEHOLD_MEMBERS = 'GET_HOUSEHOLD_MEMBERS'
export const GET_HOUSEHOLD_MEMBER = 'GET_HOUSEHOLD_MEMBER'
export const ADD_HOUSEHOLD_MEMBER = 'ADD_HOUSEHOLD_MEMBER'
export const UPDATE_HOUSEHOLD_MEMBER = 'UPDATE_HOUSEHOLD_MEMBER'
export const DELETE_HOUSEHOLD_MEMBER = 'DELETE_HOUSEHOLD_MEMBER'

export const GET_PROGRAMS = 'GET_PROGRAMS'
export const GET_PROGRAM = 'GET_PROGRAM'
export const ADD_PROGRAM = 'ADD_PROGRAM'
export const UPDATE_PROGRAM = 'UPDATE_PROGRAM'
export const DELETE_PROGRAM = 'DELETE_PROGRAM'

export const GET_UTILITIES = 'GET_UTILITIES'
export const GET_UTILITY = 'GET_UTILITY'
export const ADD_UTILITY = 'ADD_UTILITY'
export const UPDATE_UTILITY = 'UPDATE_UTILITY'
export const DELETE_UTILITY = 'DELETE_UTILITY'

export const GET_HOUSING_EXPENSES = 'GET_HOUSING_EXPENSES'
export const GET_HOUSING_EXPENSE = 'GET_HOUSING_EXPENSE'
export const ADD_HOUSING_EXPENSE = 'ADD_HOUSING_EXPENSE'
export const UPDATE_HOUSING_EXPENSE = 'UPDATE_HOUSING_EXPENSE'
export const DELETE_HOUSING_EXPENSE = 'DELETE_HOUSING_EXPENSE'

export const SET_ESIGN_READY = 'SET_ESIGN_READY'

export const GET_APP_STATUS = 'GET_APP_STATUS'

export const GET_MESSAGES = 'GET_MESSAGES'
export const SEND_MESSAGE = 'SEND_MESSAGE'
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE'

export const GET_COUNTIES = 'GET_COUNTIES'

/// ACTION CREATORS
export const tenantApplicationBegin = () => ({ type: TENANT_APP_BEGIN })
export const tenantApplicationEnd = () => ({ type: TENANT_APP_END })

// TENANT PROFILE
export const getTenantInfo = (tenant) => ({ type: GET_TENANT_APPLICANT_INFO, tenant })
export const updateTenantInfo = () => ({ type: UPDATE_TENANT_APPLICANT_INFO })
export const signTenant = () => ({ type: SIGN_TENANT })
export const getLLSearch = (landlords) => ({ type: GET_LL_SEARCH, landlords })
export const inviteLandlord = (landlords) => ({ type: INVITE_LANDLORD, landlords })

// HOUSE MATES
export const getHouseholdMembers = (householdMembers) => ({ type: GET_HOUSEHOLD_MEMBERS, householdMembers })
export const getHouseholdMember = () => ({ type: GET_HOUSEHOLD_MEMBER })
export const addHouseholdMember = () => ({ type: ADD_HOUSEHOLD_MEMBER })
export const updateHouseholdMember = () => ({ type: UPDATE_HOUSEHOLD_MEMBER })
export const deleteHouseholdMember = () => ({ type: DELETE_HOUSEHOLD_MEMBER })

// PROGRAMS
export const getPrograms = (programs) => ({ type: GET_PROGRAMS, programs })
export const getProgram = () => ({ type: GET_PROGRAM })
export const addProgram = () => ({ type: ADD_PROGRAM })
export const updateProgram = () => ({ type: UPDATE_PROGRAM })
export const deleteProgram = () => ({ type: DELETE_PROGRAM })

// UTILITIES
export const getUtilities = (utilities) => ({ type: GET_UTILITIES, utilities })
// export const getUtility = () => ({ type: GET_UTILITY })
export const addUtility = () => ({ type: ADD_UTILITY })
export const updateUtility = () => ({ type: UPDATE_UTILITY })
export const deleteUtility = () => ({ type: DELETE_UTILITY })

// HOUSING EXPENSES
export const getHousingExpenses = (housingExpenses) => ({ type: GET_HOUSING_EXPENSES, housingExpenses })
// export const getHousingExpense = (housingExpense) => ({ type: GET_HOUSING_EXPENSE, housingExpense })
export const addHousingExpense = () => ({ type: ADD_HOUSING_EXPENSE })
export const updateHousingExpense = () => ({ type: UPDATE_HOUSING_EXPENSE })
export const deleteHousingExpense = () => ({ type: DELETE_HOUSING_EXPENSE })

// SET E-SIGN READY
export const setEsignReady = (isEsignReady) => ({ type: SET_ESIGN_READY, isEsignReady })

// GET APPLICATION STATUS
export const getStatus = (status) => ({ type: GET_APP_STATUS, status })

// MESSAGE
export const getMessages = (messages) => ({ type: GET_MESSAGES, messages })
export const sendMessage = () => ({ type: SEND_MESSAGE })
export const updateMessage = () => ({ type: UPDATE_MESSAGE })

// COUNTIES
export const getCounties = (counties) => ({ type: GET_COUNTIES, counties })

// ///////////////////////////////
// ////// TENANT PROFILE ////////
// /////////////////////////////

// GET_TENANT_APPLICANT_INFO
export const startGetTenantProfile = () => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.get(`${baseURL}/user/tenantProfile`)
      dispatch(getTenantInfo(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(tenantApplicationEnd())
    }
  }
}

// UPDATE_TENANT_APPLICANT_INFO
export const startUpdateTenantProfile = (payload, setSubmitting) => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.put(`${baseURL}/user/tenantProfile`, payload)
      dispatch(updateTenantInfo(response.data))
      toast.success('Profile updated!')
      history.push('/application/tenant')
    } catch (error) {
      setSubmitting()
      console.log(error.response)
      dispatch(tenantApplicationEnd())
    }
  }
}

// SIGN_TENANT
export const startAddTenantSignature = (payload, setSubmitting) => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.post(`${baseURL}/signature/tenant`, payload)
      dispatch(signTenant())
      toast.success('Signed!')
      history.push('/application/tenant')
    } catch (error) {
      setSubmitting()
      console.log(error.response)
      dispatch(tenantApplicationEnd())
    }
  }
}

// GET_LL_SEARCH
export const startGetLandLords = (email) => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      ls('email', email)
      const encodedEmail = encodeURIComponent(email)
      const response = await axios.get(`${baseURL}/landlords/?q=${encodedEmail}`)
      dispatch(getLLSearch(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(tenantApplicationEnd())
    }
  }
}

// INVITE_LANDLORD
export const startInviteLandlord = (payload) => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.post(`${baseURL}/invite`, payload)
      dispatch(inviteLandlord(response.data))
      toast.success('Invite Sent!')
      history.push('/application/tenant')
      ls.remove('email')
    } catch (error) {
      console.log(error.response)
      dispatch(tenantApplicationEnd())
    }
  }
}

// ///////////////////////////////
// /////// HOUSE MATES //////////
// /////////////////////////////

// GET_HOUSEHOLD_MEMBERS
export const startGetHouseholdMembers = () => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.get(`${baseURL}/houseMates`)
      dispatch(getHouseholdMembers(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(tenantApplicationEnd())
    }
  }
}

// GET_HOUSEHOLD_MEMBER
// export const startGetHouseholdMember = (householdMemberID) => {
//   return async(dispatch, getState) => {
//     dispatch(tenantApplicationBegin())
//     try {
//       const response = await axios.get(`${baseURL}/houseMates/${householdMemberID}`)
//       dispatch(getHouseholdMember(response.data))
//     } catch (error) {
//       console.log(error.response)
//       dispatch(tenantApplicationEnd())
//     }
//   }
// }

// ADD_HOUSEHOLD_MEMBER
export const startAddHouseholdMembers = (payload, setSubmitting, toggle) => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.post(`${baseURL}/houseMates`, payload)
      dispatch(addHouseholdMember())
      dispatch(startGetHouseholdMembers())
      toggle()
      toast.success('Created!')
    } catch (error) {
      console.log(error.response)
      setSubmitting()
      dispatch(tenantApplicationEnd())
    }
  }
}

// UPDATE_HOUSEHOLD_MEMBER
export const startUpdateHouseholdMember = (householdMemberID, payload, setSubmitting, toggle) => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.put(`${baseURL}/houseMates/${householdMemberID}`, payload)
      dispatch(updateHouseholdMember())
      dispatch(startGetHouseholdMembers())
      toggle()
      toast.success('Updated!')
    } catch (error) {
      setSubmitting()
      console.log(error.response)
      dispatch(tenantApplicationEnd())
    }
  }
}

// DELETE_HOUSEHOLD_MEMBER
export const startDeleteHouseholdMember = (householdMemberID, toggle) => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.delete(`${baseURL}/houseMates/${householdMemberID}`)
      dispatch(deleteHouseholdMember())
      dispatch(startGetHouseholdMembers())
      toggle()
      toast.warning('Deleted!')
    } catch (error) {
      console.log(error.response)
      dispatch(tenantApplicationEnd())
    }
  }
}

// //////////////////////////////////////
// /////// PROGRAM ASSISTANCE //////////
// ////////////////////////////////////

// GET_PROGRAMS
export const startGetPrograms = () => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.get(`${baseURL}/programAssistance`)
      dispatch(getPrograms(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(tenantApplicationEnd())
    }
  }
}

// GET_PROGRAM
// export const startGetProgram = (programID) => {
//   return async(dispatch, getState) => {
//     dispatch(tenantApplicationBegin())
//     try {
//       const response = await axios.get(`${baseURL}/programAssistance/${programID}`)
//       dispatch(getProgram(response.data))
//     } catch (error) {
//       console.log(error.response)
//       dispatch(tenantApplicationEnd())
//     }
//   }
// }

// ADD_PROGRAM
export const startAddProgram = (payload, setSubmitting, toggle) => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.post(`${baseURL}/programAssistance`, payload)
      dispatch(addProgram())
      dispatch(startGetPrograms())
      toggle()
      toast.success('Created!')
    } catch (error) {
      console.log(error.response)
      setSubmitting()
      dispatch(tenantApplicationEnd())
    }
  }
}

// UPDATE_PROGRAM
export const startUpdateProgram = (programID, payload, setSubmitting, toggle) => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.put(`${baseURL}/programAssistance/${programID}`, payload)
      dispatch(updateProgram())
      dispatch(startGetPrograms())
      toggle()
      toast.success('Updated!')
    } catch (error) {
      setSubmitting()
      console.log(error.response)
      dispatch(tenantApplicationEnd())
    }
  }
}

// DELETE_PROGRAM
export const startDeleteProgram = (programID, toggle) => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.delete(`${baseURL}/programAssistance/${programID}`)
      dispatch(deleteProgram())
      dispatch(startGetPrograms())
      toggle()
      toast.warning('Deleted!')
    } catch (error) {
      console.log(error.response)
      dispatch(tenantApplicationEnd())
    }
  }
}

// /////////////////////////////////////
// /////// APPLICATION STATUS //////////
// /////////////////////////////////////

// GET_APP_STATUS
export const startGetStatus = () => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.get(`${baseURL}/tenant/status`)
      dispatch(getStatus(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(tenantApplicationEnd())
    }
  }
}

// ////////////////////////////
// /////// UTILITIES //////////
// ///////////////////////////

// GET_UTILITIES
export const startGetUtilities = () => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.get(`${baseURL}/utility`)
      dispatch(getUtilities(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(tenantApplicationEnd())
    }
  }
}

// GET_UTILITY
// export const startGetUtility = (utilityID) => {
//   return async(dispatch, getState) => {
//     dispatch(tenantApplicationBegin())
//     try {
//       const response = await axios.get(`${baseURL}/utility/${utilityID}`)
//       dispatch(getUtility(response.data))
//     } catch (error) {
//       console.log(error.response)
//       dispatch(tenantApplicationEnd())
//     }
//   }
// }

// ADD_UTILITY
export const startAddUtility = (payload, setSubmitting, toggle) => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.post(`${baseURL}/utility`, payload)
      dispatch(addUtility())
      dispatch(startGetUtilities())
      toggle()
      toast.success('Created!')
    } catch (error) {
      console.log(error.response)
      setSubmitting()
      dispatch(tenantApplicationEnd())
    }
  }
}

// UPDATE_UTILITY
export const startUpdateUtility = (utilityID, payload, setSubmitting, toggle) => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.put(`${baseURL}/utility/${utilityID}`, payload)
      dispatch(updateUtility())
      dispatch(startGetUtilities())
      toggle()
      toast.success('Updated!')
    } catch (error) {
      console.log(error.response)
      setSubmitting()
      dispatch(tenantApplicationEnd())
    }
  }
}

// DELETE_UTILITY
export const startDeleteUtility = (utilityID, toggle) => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.delete(`${baseURL}/utility/${utilityID}`)
      dispatch(deleteUtility())
      dispatch(startGetUtilities())
      toggle()
      toast.warning('Deleted!')
    } catch (error) {
      console.log(error.response)
      dispatch(tenantApplicationEnd())
    }
  }
}

// //////////////////////////////////
// /////// HOUSING EXPENSES /////////
// //////////////////////////////////

// GET_HOUSING EXPENSES
export const startGetHousingExpenses = () => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.get(`${baseURL}/otherHousingExpense`)
      dispatch(getHousingExpenses(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(tenantApplicationEnd())
    }
  }
}

// ADD_HOUSING EXPENSE
export const startAddHousingExpense = (payload, setSubmitting, toggle) => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.post(`${baseURL}/otherHousingExpense`, payload)
      dispatch(addHousingExpense())
      dispatch(startGetHousingExpenses())
      toggle()
      toast.success('Created!')
    } catch (error) {
      console.log(error.response)
      setSubmitting()
      dispatch(tenantApplicationEnd())
    }
  }
}

// UPDATE_HOUSING EXPENSE
export const startUpdateHousingExpense = (housingExpenseID, payload, setSubmitting, toggle) => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.put(`${baseURL}/otherHousingExpense/${housingExpenseID}`, payload)
      dispatch(updateHousingExpense())
      dispatch(startGetHousingExpenses())
      toggle()
      toast.success('Updated!')
    } catch (error) {
      console.log(error.response)
      setSubmitting()
      dispatch(tenantApplicationEnd())
    }
  }
}

// DELETE_HOUSING EXPENSE
export const startDeleteHousingExpense = (housingExpenseID, toggle) => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.delete(`${baseURL}/otherHousingExpense/${housingExpenseID}`)
      dispatch(deleteHousingExpense())
      dispatch(startGetHousingExpenses())
      toggle()
      toast.warning('Deleted!')
    } catch (error) {
      console.log(error.response)
      dispatch(tenantApplicationEnd())
    }
  }
}

// /////////////////////////////
// /////// MESSAGES //////////
// ///////////////////////////

// GET_MESSAGES
export const startGetMessages = () => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.get(`${baseURL}/messages`)
      dispatch(getMessages(response.data.conversation))
    } catch (error) {
      console.log(error.response)
      dispatch(tenantApplicationEnd())
    }
  }
}

// SEND_MESSAGE
export const startSendMessage = (payload, setSubmitting, resetForm) => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.post(`${baseURL}/messages`, payload)
      dispatch(sendMessage())
      dispatch(startGetMessages())
      toast.success('Sent!')
      setSubmitting()
      resetForm({
        values: {
          text: ''
        }
      })
      dispatch(startUpdateMessage())
    } catch (error) {
      console.log(error.response)
      setSubmitting()
      dispatch(tenantApplicationEnd())
    }
  }
}

// UPDATE_MESSAGE
export const startUpdateMessage = () => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      await dispatch(startGetMessages())
      const messages = getState().tenantApplication.messages
      const messageIDs = messages.map(d => d.messageID)
      const payload = {
        messageIDs: messageIDs
      }
      const response = await axios.put(`${baseURL}/messages/read`, payload)
      dispatch(updateMessage())
      dispatch(startGetMessages())
    } catch (error) {
      console.log(error.response)
      dispatch(tenantApplicationEnd())
    }
  }
}

// GET_COUNTIES
export const startGetCounties = () => {
  return async(dispatch, getState) => {
    dispatch(tenantApplicationBegin())
    try {
      const response = await axios.get(`${baseURL}/counties`)
      dispatch(getCounties(response.data))
    } catch (error) {
      console.log(error.response)
      dispatch(tenantApplicationEnd())
    }
  }
}
